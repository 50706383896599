import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
    constructor(public translate: TranslateService) {
        // Register translation languages
        translate.addLangs(['it', 'en']);
        // Set default language
        translate.setDefaultLang('it');
    }

    translateLanguageTo(lang: string) {
        this.translate.use(lang);
    }
}

export const environment = {
    production: 0,
    api: {
          baseUrl: 'https://vinilificio-test.bedita.net/api',
          apiKey: null,
          withCredentials: true,
      },
    gtmId: 'GTM-MCNGJC6',
    redirectUrlOk: 'https://vinilificio-test.bedita.net/file-uploader',
    redirectUrlFail: 'https://vinilificio-test.bedita.net'
};
